import React from 'react';
import {Helmet} from "react-helmet"

export const HeadInject = () => {
  
    return (
        <Helmet>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="9ab8a9be-94c7-403e-b1cb-e0a9ac4be940"></script>
            <script type="text/javascript">
                {`function OptanonWrapper() { }`}
            </script>
        </Helmet>
    );

};