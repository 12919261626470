import React from "react"

import Wrapper from '../components/03_organisms/Wrapper/Wrapper';
import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";

const Error404 = ({ ...props }) => {

	// render html
	return (
		<Wrapper>
			<HeadInject />
			<div>
				<h1>Not found</h1>
			</div>
		</Wrapper>

	)
}

export default Error404;